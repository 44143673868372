<template>
  <v-row>
    <v-col>
      <material-card
        icon="mdi-dropbox"
        icon-small
        color="primary"
        title="Reports"
      >
        <v-card-text>
          <v-row justify="center">
            <v-col cols="12" md="2"
              ><v-menu
                ref="fromDateMenu"
                v-model="fromDateMenu"
                :close-on-content-click="false"
                :return-value.sync="fromDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fromDate"
                    label="From date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="fromDate" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="fromDateMenu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.fromDateMenu.save(fromDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu></v-col
            >
            <v-col cols="12" md="2">
              <v-menu
                ref="fromTimeMenu"
                v-model="fromTimeMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="fromTime"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fromTime"
                    label="From time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="fromTimeMenu"
                  v-model="fromTime"
                  full-width
                  format="24hr"
                  @click:minute="$refs.fromTimeMenu.save(fromTime)"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="2"
              ><v-menu
                ref="toDateMenu"
                v-model="toDateMenu"
                :close-on-content-click="false"
                :return-value.sync="toDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="toDate"
                    label="To date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="toDate" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="toDateMenu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.toDateMenu.save(toDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu></v-col
            >
            <v-col cols="12" md="2">
              <v-menu
                ref="toTimeMenu"
                v-model="toTimeMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="fromTime"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="toTime"
                    label="To time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="toTimeMenu"
                  v-model="toTime"
                  full-width
                  format="24hr"
                  @click:minute="$refs.toTimeMenu.save(fromTime)"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="2">
              <v-autocomplete
                v-model="user"
                :items="users"
                item-text="userName"
                item-value="id"
                label="User"
              />
            </v-col>
            <v-col cols="12" md="2">
              <v-autocomplete
                v-model="csv"
                :items="csvList"
                item-text="csvName"
                item-value="id"
                label="Csv"
              />
            </v-col>
            <v-col cols="12" md="2">
              <v-autocomplete
                v-model="status"
                :items="statusList"
                item-text="statusInText"
                item-value="id"
                label="Status"
              />
            </v-col>
            <v-col cols="12" md="2" class="text-center">
              <v-btn color="primary" @click="generate = !generate">
                Generate
              </v-btn>
            </v-col>
          </v-row>

          <v-divider />
          <UserReport
            :filter="{
              fromDate: fromDateTime,
              toDate: toDateTime,
              user,
              status,
              csv,
              statusList,
              search,
              generate,
            }"
          />
        </v-card-text>
      </material-card>
    </v-col>
  </v-row>
</template>
<script>
import moment from "moment";
import UserReport from "./components/Reports/UserReport.vue";
export default {
  name: "Reports",
  components: {
    UserReport,
  },
  data: () => ({
    fromDate: moment().subtract(1, "day").format("YYYY-MM-DD"),
    fromDateMenu: false,
    fromTime: "00:00",
    fromTimeMenu: false,
    toDate: moment().format("YYYY-MM-DD"),
    toDateMenu: false,
    toTime: moment().format("HH:mm"),
    toTimeMenu: false,
    user: 0,
    role: "CompletePicker",
    users: [],
    status: 0,
    statusList: [],
    search: "",
    csvList: [],
    csv: 0,
    generate: false,
  }),
  created() {
    this.getCsvList();
    this.getUsers();
    this.getStatusList();
  },
  computed: {
    fromDateTime() {
      return moment
        .utc(new Date(this.fromDate + "T" + this.fromTime))
        .format("YYYY-MM-DDTHH:mm:ss");
    },
    toDateTime() {
      return moment
        .utc(new Date(this.toDate + "T" + this.toTime))
        .format("YYYY-MM-DDTHH:mm:ss");
    },
  },
  methods: {
    getUsers() {
      this.$http.get(`UserRoles/${this.role}`).then((response) => {
        if (response.data.success) {
          this.users = response.data.data;
          this.users.unshift({ userName: "None", id: 0 });
        }
      });
    },
    getStatusList() {
      this.$http.get(`Statuses`).then((response) => {
        this.statusList = response.data;
        this.statusList.unshift({ statusInText: "None", id: 0 });
      });
    },
    getCsvList() {
      this.$http
        .post(`Shifts/Search`, { pageNumber: 1, pageSize: 25 })
        .then((response) => {
          this.csvList = response.data.data.items;
          this.csvList.unshift({ csvName: "None", id: 0 });
        });
    },
  },
};
</script>